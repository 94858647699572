import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { SectionHeading, Subheading as SubheadingBase } from "src/ui-treact/components/misc/Headings";
import { Link } from "../../utils/general";
 

const Container = styled.div`
  ${tw`flex flex-col items-center justify-center w-full bg-center bg-cover`}
  background-image: url(${props=>props.background_image});
  z-index: 9;

`;
const TextContent = tw.div`py-6 px-6 md:px-32 lg:py-8 text-center md:text-center bg-primary-900 w-11/12 rounded-xl`; 
 
const Subheading = tw(SubheadingBase)`text-center md:text-center text-white font-normal  mb-0
`;
const Heading = tw(
  SectionHeading
)`mt-4 font-medium text-center text-2xl sm:text-3xl lg:text-4xl text-center md:text-center leading-tight text-white`;
const Description = tw.p`mt-4 text-center md:text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-white`;

const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-center mt-4`;
const Statistic = tw.div`text-center sm:inline-block sm:mr-12 last:mr-0 mt-4`;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-white tracking-wide`;
const Key = tw.div`font-medium text-white`;

const PrimaryButton = styled(Link)`
${tw` px-8 py-3 font-bold rounded bg-primary-900 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0 rounded-full bg-secondary-900 hover:no-underline cursor-pointer`}
`

export default ({data,page}) => {
  let finalData= data?.schema_json;  
  // console.log('finalData', finalData)
  return (
    <Container background_image={finalData?.background_image}>
          <TextContent>
            {finalData.sub_heading && <Subheading  textOnLeft={finalData.textOnLeft} textColor={finalData.textColor}>{finalData.sub_heading}</Subheading>}
            <Heading textOnLeft={finalData.textOnLeft} textColor={finalData.textColor} dangerouslySetInnerHTML={{__html:finalData.heading}}></Heading>
            <Description textOnLeft={finalData.textOnLeft} textColor={finalData.textColor}>{finalData.description}</Description>
            {
            finalData.statistics ? <Statistics>
              {finalData.statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics> : null
            }
            {
              finalData.primary_button_text ? 
              <PrimaryButton 
              href={finalData.open_new_tab ? finalData.primary_button_url : null}
              to={finalData.open_new_tab ? null : finalData.primary_button_url}
              target="_blank"
              >
              {finalData.primary_button_text}
            </PrimaryButton> : null
            }
            
          </TextContent>
    </Container>
  );
};
